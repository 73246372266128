import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Sizes, { min } from '../constants/sizes';

const Outer = styled.div`
	max-width: ${Sizes.max}px;
	margin-left: auto;
	margin-right: auto;
`;

const Inner = styled.div`
	padding-left: ${Sizes.gutterElasticVw}vw;
	padding-right: ${Sizes.gutterElasticVw}vw;

	${min.max`
		padding-left: ${Sizes.gutterMaxPx}px;
		padding-right: ${Sizes.gutterMaxPx}px;
	`}
`;

/**
 * WidthLimit
 *
 * Wrap this around some content to set the stretchy side padding and site-wide
 * maximum width.
 */
const WidthLimit = ({ children, unpadded, ...otherProps }) =>
	unpadded ? (
		<Outer {...otherProps}>{children}</Outer>
	) : (
		<Outer {...otherProps}>
			<Inner>{children}</Inner>
		</Outer>
	);
WidthLimit.propTypes = {
	children: PropTypes.node.isRequired,
	unpadded: PropTypes.bool.isRequired,
};
WidthLimit.defaultProps = {
	unpadded: false,
};

export default WidthLimit;
