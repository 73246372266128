import React from 'react';
import PropTypes from 'prop-types';

import Helmet from 'react-helmet';
import { createGlobalStyle } from 'styled-components';

import { title, description } from '../constants/meta';

import Header from './header';
import Footer from './footer';

import Colors from '../constants/colors';
import { min } from '../constants/sizes';

import shareImage from '../images/everyone-poster.png';

const shareImageAlt = "Mark Wiens and nine contestants. VNTA: Vancouver’s Next Top Agent – premieres June 5 on YouTube";

const GlobalStyle = createGlobalStyle`
	html {
		background-color: black;
		color: #ccc;
		font-family: Barlow, sans-serif;
		line-height: 1.5;
		font-size: 16px;

		${min.tablet`
			font-size: 20px;
		`}
	}
	body {
		margin: 0;
	}
	h1, h2, h3 {
		font-weight: 600;
		font-family: "Barlow Condensed", Barlow, sans-serif;
		text-transform: uppercase;
	}
	h1 {
		font-size: 10vw;
		${min.tablet`
			font-size: 3.2rem;
		`}
	}
	h2 {
		font-size: 1rem;
		${min.tablet`
			font-size: 1.4rem;
		`}
	}
	h3 {
		font-size: 1.2rem;
		margin-top: 2rem;
		margin-bottom: 1rem;
	}
	a {
		color: ${Colors.sunYellow};
	}

	.grayDotted {
		background: ${Colors.darkGray};
		background:
			radial-gradient(${Colors.darkGray} 1px, transparent 2px) 0 0 / 6px 6px,
			linear-gradient(to bottom, ${Colors.darkGray}, black 300%);
		color: white;
	}
`;

const Layout = ({ children }) => (
	<>
		<Helmet
			htmlAttributes={{ lang: "en" }}
			defaultTitle={title}
			titleTemplate={`%s | ${title}`}
		>
			<meta charSet="utf-8" />
			<meta name="description" content={description} />
			<meta property="og:title" content={title} />
			<meta property="og:type" content="website" />
			<meta property="og:image" content={`https://vancouversnexttopagent.ca${shareImage}`} />
			<meta property="og:image:alt" content={shareImageAlt} />
			<meta property="og:image:width" content="1200" />
			<meta property="og:image:height" content="630" />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:site" content="@RealMarkWiens" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:image" content={`https://vancouversnexttopagent.ca${shareImage}`} />
			<meta name="twitter:image:alt" content={shareImageAlt} />
		</Helmet>

		<GlobalStyle />

		<Header />

		{children}

		<Footer />
	</>
);

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;

