import React from 'react';

import styled from 'styled-components';

import SrOnly from './sr-only';

import { ReactComponent as House } from '../images/house.svg';

import { min } from '../constants/sizes';

const Logo = () => (
	<Wrapper>
		<div className="flex">
			<div className="innerFlex">
				<House className="house" />
				<div className="abbreviation">
					VNTA
				</div>
				<SrOnly>{`: `}</SrOnly>
			</div>
			<div className="title">
				<span>Vancouver’s Next Top Agent</span>
			</div>
		</div>
	</Wrapper>
);

export default Logo;

const Wrapper = styled.div`
	font-family: "Barlow Condensed", sans-serif;
	font-weight: bold;
	font-style: italic;
	line-height: 1;
	text-transform: none;
	text-align: center;

	& .flex, & .innerFlex {
		display: flex;
		align-items: baseline;
	}

	& .flex {
		flex-direction: column;

		${min.tablet`
			flex-direction: row;
		`}
	}

	& .house {
		height: 0.8em;
		width: 1.75em; // For IE11

		@supports (object-fit: contain) { // Don't really care about this, just need to target things other than IE11
			width: auto;
		}
	}

	& .abbreviation {
		font-size: 3em;
		margin-top: -0.1em;
		margin-left: -0.08em;
	}

	& .title {
		font-size: 0.7em;
		position: relative;
		mix-blend-mode: screen;
		padding: 0 0.2em 0.2em 0.3em;
		bottom: 0.25em;
		line-height: 0.9;
		color: black;
		box-sizing: border-box;
		margin-left: -0.2em;

		${min.tablet`
			margin-left: 0.15em;
		`}

		& span {
			position: relative;
		}

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: white;
			transform: skewX(-10deg);
			transform-origin: left bottom;
		}
	}
`;
