export const sunYellow = '#f8de46';
export const lightBluishGreen = '#75fd9e';
export const turquoiseBlue = '#26b2ba';
export const darkCyan = '#178288';
export const lightRoyalBlue = '#4938f3';
export const darkIndigo = '#310a62';
export const magenta = '#b60e73';
export const darkMagenta = '#930f5e';

export const darkGray = '#222324';

export default {
	sunYellow,
	lightBluishGreen,
	turquoiseBlue,
	darkCyan,
	lightRoyalBlue,
	darkIndigo,
	magenta,
	darkMagenta,
	darkGray,
};
