import React from 'react';

import styled from 'styled-components';

import Link from './link';
import Logo from './logo';
import SrOnly from './sr-only';
import WidthLimit from './width-limit';

import { ReactComponent as MarkWiensLogo } from '../images/mark-wiens-logo.svg';

import { min } from '../constants/sizes';

const Header = ({ siteTitle }) => (
	<Wrapper>
		<WidthLimit>
			<Flex>
				<h1>
					<Link style={{ display: 'block' }} to="/">
						<Logo />
					</Link>
				</h1>
				<nav>
					<ul>
						<li className="not-mobile">
							<Link to="/" activeClassName="is-active">
								Home
							</Link>
						</li>
						<li>
							<Link to="/contestants/" activeClassName="is-active">
								Contestants
							</Link>
						</li>
						<li>
							<Link to="/who-is-mark-wiens/" activeClassName="is-active">
								Who?
							</Link>
						</li>
						<li>
							<Link to="/sponsors/" activeClassName="is-active">
								Sponsors
							</Link>
						</li>
						<li>
							<a href="https://www.youtube.com/channel/UCajXkW6et8TSV8lNTAxadDA" target="_blank" rel="noopener">{/* eslint-disable-line react/jsx-no-target-blank */}
								Youtube
							</a>
						</li>
						<li className="not-mobile">
							<a href="https://markwiens.ca/" target="_blank" rel="noopener">{/* eslint-disable-line react/jsx-no-target-blank */}
								<SrOnly>Mark Wiens</SrOnly>
								<StyledMarkWiensLogo />
							</a>
						</li>
					</ul>
				</nav>
			</Flex>
		</WidthLimit>
	</Wrapper>
);

export default Header;

const Wrapper = styled.header`
	background: black;
	color: white;
	padding: 1rem 0;

	& a {
		text-decoration: none;
		color: inherit;
	}

	& h1 {
		font-size: 0.8rem;
		margin: 0;

		${min.tablet`
			font-size: 1.1rem;
		`}
	}

	& nav {
		font-family: "Barlow Condensed", Barlow, sans-serif;
		font-weight: bold;
		text-transform: uppercase;
		line-height: 1.2;
		font-size: 0.9rem;

		& ul {
			list-style-type: none;
			padding: 0;
			margin: 0;
			display: flex;
			align-items: center;

			& li {
				margin-left: 0.5em;

				${min.desktop`
					margin-left: 1em;
				`}

				&.not-mobile {
					display: none;

					${min.tablet`
						display: block;
					`}
				}
			}
		}

		& a {
			&.is-active {
				text-decoration: underline;
			}
		}
	}
`;

const Flex = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const StyledMarkWiensLogo = styled(MarkWiensLogo)`
	width: 2.5em;
	height: 2.5em; // For IE11
	@supports (object-fit: contain) { // Don't really care about this, just need to target things other than IE11
		height: auto;
	}

	& [fill="#e1bb0f"] {
		transition: fill 0.2s;
	}

	a:hover &, a:active & {
		& [fill="#e1bb0f"] {
			fill: white;
		}
	}
`;
