import React from 'react';

import styled from 'styled-components';

import SrOnly from './sr-only';
import WidthLimit from './width-limit';
import YoutubeButton from './youtube-button';

import Colors from '../constants/colors';

import { ReactComponent as MarkWiensLogo } from '../images/mark-wiens-logo-with-name-horiz.svg';

import { ReactComponent as FacebookIcon } from '../images/facebook.svg';
import { ReactComponent as InstagramIcon } from '../images/instagram.svg';
import { ReactComponent as TwitterIcon } from '../images/twitter.svg';

import { min } from '../constants/sizes';

const Footer = (props) => (
	<Wrapper {...props}>
		<WidthLimit>
			<Flex>
				<div>
					<YoutubeButton layout="full" theme="dark" />
				</div>
				<div>
					<div>
						<a href="https://markwiens.ca/" target="_blank" rel="noopener">{/* eslint-disable-line react/jsx-no-target-blank */}
							<SrOnly>Mark Wiens</SrOnly>
							<MarkWiensLogo className="markLogo" style={{ width: 'auto', height: '2rem' }} />
						</a>
					</div>
					<Social>
						<a href="https://www.facebook.com/mwiensrealty/" target="_blank" rel="noopener">{/* eslint-disable-line react/jsx-no-target-blank */}
							<SrOnly>Facebook</SrOnly>
							<FacebookIcon className="socialIcon" />
						</a>
						<a href="https://www.instagram.com/markwiensrealestate/" target="_blank" rel="noopener">{/* eslint-disable-line react/jsx-no-target-blank */}
							<SrOnly>Instagram</SrOnly>
							<InstagramIcon className="socialIcon" />
						</a>
						<a href="https://twitter.com/RealMarkWiens" target="_blank" rel="noopener">{/* eslint-disable-line react/jsx-no-target-blank */}
							<SrOnly>Twitter</SrOnly>
							<TwitterIcon className="socialIcon" />
						</a>
					</Social>
				</div>
			</Flex>
			<Legal>
				<p>
					Mark Wiens does business as Mark Wiens Personal Real Estate Corporation and is licensed at RE/MAX Crest Realty.
					This communication is not intended to induce a breach of an existing agency relationship.
					The content of this show is for entertainment purposes only and should not be construed as giving real estate advice.
					Although properties are mentioned, these are not offerings to sell.
					All information, while deemed to be accurate, should be independently verified.
					For up-to-date information on contestants’ status in the real estate industry, please use the “licensee search” tool which can be found on the Real Estate Council of BC’s website.
					The trademarks REALTOR®, REALTORS®, and the REALTOR® logo are controlled by the Canadian Real Estate Association (CREA) and identify real estate professionals who are members of CREA.
				</p>
			</Legal>
			<Flex>
				<div />
				<Credits>
					<p>
						Site by {' '}
						<a href="https://bartnagel.ca/" target="_blank" rel="noopener">{/* eslint-disable-line react/jsx-no-target-blank */}
							Bart Nagel
						</a>
					</p>
				</Credits>
			</Flex>
		</WidthLimit>
	</Wrapper>
);

export default Footer;

const Wrapper = styled.footer`
	text-align: center;
	background: ${Colors.darkIndigo};
	background: linear-gradient(to top, ${Colors.darkIndigo}, black 140%);
	background:
		repeating-linear-gradient(100deg, transparent 0 90px, rgba(0, 0, 0, 0.08) 91px 190px, transparent 200px),
		repeating-linear-gradient(100deg, transparent 0 18px, rgba(0, 0, 0, 0.1) 19px 222px, transparent 223px),
		repeating-linear-gradient(100deg, transparent 0 1px, rgba(0, 0, 0, 0.12) 2px 81px, transparent 82px 335px),
		linear-gradient(to top, ${Colors.darkIndigo}, black 140%);
	color: white;
	padding: 2rem 0;

	${min.tablet`
		padding: 4rem 0;
	`}
`;

const Flex = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	& > * + * {
		margin-top: 1rem;
	}

	${min.tablet`
		justify-content: space-between;
		flex-direction: row;

		& > * + * {
			margin-top: 0;
		}
	`}

	& .markLogo [fill="#e1bb0f"] {
		transition: fill 0.2s;
	}

	& a:hover, & a:active {
		& .markLogo [fill="#e1bb0f"] {
			fill: white;
		}
	}
`;

const Legal = styled.div`
	font-size: 0.7rem;
	line-height: 1.2;
	text-align: justify;
	opacity: 0.6;
`;

const Credits = styled.div`
	color: #646;
	font-size: 0.8em;
	opacity: 0.8;

	& a {
		color: inherit;
	}
`;

const Social = styled.div`
	display: flex;
	justify-content: center;

	${min.tablet`
		justify-content: flex-end;
	`}

	& a {
		width: 1.5em;
		height: 1.5em;
		display: block;

		& + a {
			margin-left: 1em;
		}
	}
`;
