import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import styled, { keyframes } from 'styled-components';

import SrOnly from './sr-only';

// Add Youtube platform script only once
let scriptAdded = false;

const YoutubeButton = ({ layout, theme, subscriberCount }) => {
	useEffect(() => {
		if (!scriptAdded) {
			const script = document.createElement('script');
			script.src = 'https://apis.google.com/js/platform.js';
			script.async = true;
			document.body.appendChild(script);
			scriptAdded = true;
		} else if (window && window.gapi) {
			window.gapi.platform.go();
		}
	});

	return (
		<div>
			{layout !== 'full' && (
				<Hint>Subscribe: </Hint>
			)}
			<Button>
				<div
					className="g-ytsubscribe"
					data-channelid="UCajXkW6et8TSV8lNTAxadDA"
					data-layout={layout}
					data-theme={theme}
					data-count={subscriberCount}
				>
					<a href="https://www.youtube.com/channel/UCajXkW6et8TSV8lNTAxadDA" target="_blank" rel="noopener">{/* eslint-disable-line react/jsx-no-target-blank */}
						<SrOnly>Mark Wiens Vancouver Real Estate on</SrOnly> YouTube
					</a>
				</div>
			</Button>
		</div>
	);
};
YoutubeButton.propTypes = {
	layout: PropTypes.oneOf(['default', 'full']).isRequired,
	theme: PropTypes.oneOf(['default', 'dark']).isRequired,
	subscriberCount: PropTypes.oneOf(['default', 'hidden']).isRequired,
};
YoutubeButton.defaultProps = {
	layout: 'default',
	theme: 'default',
	subscriberCount: 'default',
};

export default YoutubeButton;

const Hint = styled.span`
	font-family: "Barlow Condensed", Barlow, sans-serif;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 0.8rem;
	vertical-align: middle;
	opacity: 0.8;
`;

const delayedAppearance = keyframes`
	from, 99% {
		visibility: hidden;
	}
	to {
		visibility: visible;
	}
`;

const Button = styled.span`
	vertical-align: middle;
	display: inline-block;
	line-height: 1;

	& a {
		color: inherit;
		animation: ${delayedAppearance} 3s 1 forwards linear;
	}
`;
